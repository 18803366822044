<template>
    <div :class="theme.auth.auth">
        <ToggleTheme/>
        <div :class="theme.auth.subcontainer">
            <form
                @submit.prevent="sendEmail"
                v-if="step === steps.SEND_EMAIL"
            >
                <LogoBlock/>
                <div :class="theme.auth.header">
                    <span :class="theme.auth.text">
                        Restore Password
                    </span>
                </div>
                <div :class="theme.auth.content">
                    <TextInput
                        title="Email"
                        placeholder="Your email"
                        :value="email"
                        @input-text="email = $event; errors.email = false"
                        :class="theme.auth.mbL"
                        :has-error="errors.email"
                        error-text="Please enter valid email"
                    />
                    <Button
                        is-submit
                        button-type="primary"
                        :button-disabled="isSendEmailDisabled"
                        wide
                        :class="theme.auth.mbS"
                    >
                        <template>
                            Send email
                        </template>
                    </Button>
                    <Button
                        @click="$router.push('/signin').catch(() => { /* navigation error */ })"
                        button-type="infoNoBackground"
                        wide
                        :class="theme.auth.mbL"
                    >
                        <template>
                            cancel
                        </template>
                    </Button>
                    <div :class="theme.auth.center">
                        <router-link to="/signup">
                            Don't have an account? Sign-Up
                        </router-link>
                    </div>
                </div>
            </form>
            <form
                @submit.prevent="sendCode"
                v-else-if="step === steps.RESTORE_PASSWORD"
            >
                <LogoBlock/>
                <div :class="theme.auth.header">
                    <span :class="theme.auth.text">
                        Restore Password
                    </span>
                </div>
                <div :class="theme.auth.content">
                    <TextInput
                        title="Code"
                        placeholder="Code from email"
                        :value="code"
                        @input-text="code = $event; errors.code = false"
                        :class="theme.auth.mbL"
                        :has-error="errors.code"
                        error-text="Enter 6 digits code"
                    />
                    <TextInput
                        title="New password"
                        placeholder="Your password"
                        :value="newPassword"
                        @input-text="newPassword = $event; errors.newPassword = false"
                        is-password
                        :class="theme.auth.mbL"
                        :has-error="errors.newPassword"
                        error-text="Password min length is 8 letters.<br>Password max length is 100 letters"
                    />
                    <TextInput
                        title="Confirm password"
                        placeholder="Repeat password"
                        :value="passwordConfirm"
                        @input-text="passwordConfirm = $event; errors.passwordConfirm = false"
                        is-password
                        :class="theme.auth.mbL"
                        :has-error="errors.passwordConfirm"
                        error-text="Passwords must match"
                    />
                    <Button
                        @click="resendCode"
                        button-type="primary"
                        :button-disabled="isDisabledResendEmail"
                        :class="theme.auth.mbL"
                        wide
                    >
                        <template>
                            {{ isDisabledResendEmail ? `RESEND CODE THROUGH ${this.timer / 1000} SECONDS` : 'RESEND EMAIL' }}
                        </template>
                    </Button>
                    <Button
                        is-submit
                        button-type="primary"
                        :class="theme.auth.mbL"
                        wide
                    >
                        <template>
                            Update password
                        </template>
                    </Button>
                    <div :class="theme.auth.center">
                        <router-link to="/signup">
                            Don't have an account? Sign-Up
                        </router-link>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { required, email, maxLength, minLength, sameAs } from 'vuelidate/dist/validators.min';

import LogoBlock from '@/components/Auth/components/LogoBlock.vue';
import theme from 'Theme';
import ToggleTheme from 'Components/Auth/components/ToggleTheme.vue';
import TextInput from 'Control/TextInput.vue';
import Button from 'Control/Button.vue';

export default {
    name: 'RestorePassword',
    components: {
        LogoBlock,
        ToggleTheme,
        TextInput,
        Button,
    },
    data() {
        return {
            steps: {
                SEND_EMAIL: 'SEND_EMAIL',
                RESTORE_PASSWORD: 'RESTORE_PASSWORD',
            },
            step: 'SEND_EMAIL',
            email: '',
            code: '',
            newPassword: '',
            passwordConfirm: '',
            timer: 0,
            isSendEmailDisabled: false,
            isDisabledResendEmail: false,
            theme,
            errors: {
                code: false,
                newPassword: false,
                passwordConfirm: false,
                email: false,
            },
        };
    },
    validations() {
        return {
            email: {
                required,
                email,
                minLength: minLength(5),
                maxLength: maxLength(100),
            },
            code: {
                required,
            },
            newPassword: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(72),
            },
            passwordConfirm: {
                sameAsPassword: sameAs('newPassword'),
            },
        };
    },
    methods: {
        resendCode() {
            this.timer = 60000;
            this.isDisabledResendEmail = true;

            this.$store.dispatch('Auth/forgotPassword', {
                email: this.email,
            }).then(() => {
                this.$store.dispatch('Notificator/showSuccessNotification', 'Message with code is on way to your email');
                const intervalId = setInterval(() => {
                    this.timer -= 1000;
                    if (this.timer === 0) {
                        this.isDisabledResendEmail = false;
                        clearInterval(intervalId);
                    }
                }, 1000);
            }).catch(({ message }) => {
                this.$store.dispatch('Notificator/showErrorNotification', message);
                this.isDisabledResendEmail = false;
            });
        },
        sendEmail() {
            this.email = this.email.trim();
            this.$v.$touch();
            if (!this.$v.email.$invalid) {
                this.isSendEmailDisabled = true;

                this.$store.dispatch('Auth/forgotPassword', {
                    email: this.email,
                }).then(() => {
                    this.$store.dispatch('Notificator/showSuccessNotification', 'Message with code is on way to your email');
                    this.step = this.steps.RESTORE_PASSWORD;
                }).catch((error) => {
                    this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error restoring the password');
                    this.isSendEmailDisabled = false;
                });
            } else {
                this.errors.email = true;
            }
        },
        sendCode() {
            this.$v.$touch();
            if (!this.$v.code.$invalid && !this.$v.newPassword.$invalid && !this.$v.passwordConfirm.$invalid) {
                if (this.newPassword === this.passwordConfirm) {
                    this.$store.dispatch('Auth/forgotPasswordSubmit', {
                        email: this.email,
                        code: this.code,
                        password: this.newPassword,
                    }).then(() => {
                        this.$store.dispatch('Notificator/showSuccessNotification', 'Your password is successfully updated');
                        this.$router.push('/signin').catch(() => { /* navigation error */ });
                    }).catch((error) => {
                        this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                    });
                } else {
                    this.$store.dispatch('Notificator/showErrorNotification', 'Confirm password is different from password');
                }
            } else {
                if (this.$v.code.$invalid) {
                    this.errors.code = true;
                }
                if (this.$v.newPassword.$invalid) {
                    this.errors.newPassword = true;
                }
                if (this.$v.passwordConfirm.$invalid) {
                    this.errors.passwordConfirm = true;
                }
            }
        },
    },
};
</script>
